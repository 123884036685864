<router-outlet></router-outlet>
<div *ngIf="!session" id="join">
  <div id="join-dialog">
    <br><br>
    <h3>Join Tele-health Session </h3>
	<p style="color:#008CBA;">Dr. Willam Perkins<br><small>Pediatrics</small></p>
    <form (submit)="joinSession()">
      <p>
        <label>Participant </label>
        <input type="text" id="userName" name="userName" [(ngModel)]="myUserName" required>
      </p>
      <p>
        <label>Session Id </label>
        <input type="text" id="sessionId" name="sessionId" [(ngModel)]="mySessionId" required>
      </p>
      <p class="text-center">
        <input class="button" type="submit" name="commit" value="Start Session Now">
      </p>
    </form>
  </div>
</div>

<div *ngIf="session" id="session">
  <opv-session #ovSessionComponent [sessionName]="mySessionId" [user]="myUserName" [tokens]="tokens" (leaveSession)="handlerLeaveSessionEvent($event)"
    (joinSession)="handlerJoinSessionEvent($event)" (error)="handlerErrorEvent($event)">
  </opv-session>
</div>
